import Alpine from 'alpinejs';
import Modal from 'bootstrap/js/dist/modal';
import Collapse from 'bootstrap/js/dist/collapse';
import AlpineFloatingUI from '@awcodes/alpine-floating-ui'
import FormsAlpinePlugin from '../../vendor/filament/forms/dist/module.esm'
// import NotificationsAlpinePlugin from '../../vendor/filament/notifications/dist/module.esm'

Alpine.plugin(FormsAlpinePlugin);
Alpine.plugin(AlpineFloatingUI);
// Alpine.plugin(NotificationsAlpinePlugin);

// import $ from 'jquery';
import $ from "cash-dom";
window.$ = $;
window.Alpine = Alpine;
window.Modal = Modal;
import.meta.glob([
    '../images/**'
]);
Alpine.start();
import KeenSlider from "keen-slider";


function cpu_slider_navigation(slider) {
    let wrapper, dots, arrowLeft, arrowRight, imageCarousel;
    let navigation = slider.container.parentNode.querySelector(".slider-navigation");
    arrowLeft = navigation.querySelector(".arrow-left");
    arrowRight = navigation.querySelector(".arrow-right");

    arrowLeft.addEventListener("click", (e) => {
        e.preventDefault();
        console.log("Left");
        slider.prev();
    });
    arrowRight.addEventListener("click", (e) => {
        e.preventDefault();
        console.log("right");
        slider.next();
    });

    function markup(remove) {
        // wrapperMarkup(remove)
        // dotMarkup(remove)
        arrowMarkup(remove)
    }

    function removeElement(elment) {
        elment.parentNode.removeChild(elment)
    }

    function createDiv(className) {
        var div = document.createElement("div")
        var classNames = className.split(" ")
        classNames.forEach((name) => div.classList.add(name))
        return div
    }

    function arrowMarkup(remove) {
        if (remove) {
            removeElement(arrowLeft)
            removeElement(arrowRight)
            return
        }

    }

    function wrapperMarkup(remove) {
        if (remove) {
            var parent = wrapper.parentNode
            while (wrapper.firstChild)
                parent.insertBefore(wrapper.firstChild, wrapper)
            removeElement(wrapper)
            return
        }
        wrapper = createDiv("navigation-wrapper")

        slider.container.parentNode.appendChild(wrapper)
        // this seems reursive
        //wrapper.appendChild(slider.container)
    }

    function dotMarkup(remove) {
        if (remove) {
            removeElement(dots)
            return
        }
        dots = createDiv("dots")
        slider.track.details.slides.forEach((_e, idx) => {
            var dot = createDiv("dot")
            dot.addEventListener("click", () => slider.moveToIdx(idx))
            dots.appendChild(dot)
        })
        wrapper.appendChild(dots)
    }

    function updateClasses() {

        var slide = slider.track.details.rel
        slide === 0 ?
            arrowLeft.classList.add("arrow--disabled") :
            arrowLeft.classList.remove("arrow--disabled")
        slide === slider.track.details.slides.length - 1 ?
            arrowRight.classList.add("arrow--disabled") :
            arrowRight.classList.remove("arrow--disabled")


    }

    slider.on("created", () => {
        markup()
        updateClasses()
    })
    slider.on("optionsChanged", () => {
        console.log(2)
        markup(true)
        markup()
        updateClasses()
    })
    slider.on("slideChanged", () => {
        updateClasses()
    })
    slider.on("destroyed", () => {
        markup(true)
    })
}
$(function () {

    let perks_dialogue_modal = Modal.getOrCreateInstance(document.getElementById("perks-loading-modal"));


    var rewards_redirects = document.querySelectorAll(".rewards-redirect");
    rewards_redirects.forEach(item => {
        item.addEventListener('click', function () {
            gtag('event', 'page_view', {'page_title': 'Redirect Perks', page_location: 'https://portal.cyberpowersystems.com/redirect-perks'});
            perks_dialogue_modal.show();
            
        })
    });


    if (document.getElementById("dismiss_global_notification_message")) {
        var gnf_listener = document.getElementById("dismiss_global_notification_message").addEventListener('click', function (e) {
            e.preventDefault;
            let element = document.getElementById("gnm");
            element.remove();
        });
    }


    var sliders = $(".cpu-slider");
    var all_sliders = [];



    sliders.each((key, item) => {
        let id = $(item).attr('id');
        console.log(id);
        let perView = $(item).data('perView');
        let origin = $(item).data('origin') ? $(item).data('origin') : 'auto';
        let spacing = $(item).data('spacing') ? $(item).data('spacing') : 15;
        all_sliders[key] = new KeenSlider("#" + id, {
            breakpoints: {
                "(min-width: 768px)": { // tablet   
                    slides: {
                        origin: origin,
                        spacing: spacing,
                        perView: perView,
                        spacing: spacing
                    },
                },
            },
            slides: {
                perView: 1,
                spacing: spacing,
            },
            /*
            
            */

        }, [cpu_slider_navigation]);

    });

    window.livewire.on('hideModal', () => {
        var all_modals = document.getElementsByClassName("modal show")
        for (let shown_modal of all_modals) {
            let instance = Modal.getInstance(shown_modal);
            instance.hide();
        }
    })

});
